import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import SendMessageIconComponent from '../../assets/SendMessageIconComponent'
import { makeRequestAi } from '../../services/makeRequestAi'
import useChatStore from '../../store/ChatStore'

const SendMessageComponent = forwardRef(({ className = "", active = false, message: externalMessage, setMessage: setExternalMessage, setFocus }, ref) => {
    const [status, setStatus] = useState()
    const { setMessage, activeChatId, initializeChat, addMessage } = useChatStore()

    useEffect(() => {
        // Initialize the chat session on component mount
        initializeChat();
    }, [initializeChat]);

    const onClickHandler = () => {
        if (!status && externalMessage.trim() !== "") {
            addMessage(externalMessage, 'user');
            makeRequestAi(activeChatId, setStatus, setMessage, externalMessage, addMessage)
            setExternalMessage("")
            setFocus()
        }
    }

    // Expose the onClickHandler function to the parent component
    useImperativeHandle(ref, () => ({
        onClickHandler,
    }))

    return (
        <>
            <button className={`${className}`} disabled={!active || status} title="send message" aria-label="send message" onClick={onClickHandler}>
                <SendMessageIconComponent />
            </button>
        </>
    )
})

export default SendMessageComponent
