import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react'
import useFeedbackStore from '../../store/FeedbackStore';
import TextareaAutosize from 'react-textarea-autosize'
import QuestionmarkIconComponent from '../../assets/QuestionmarkIconComponent';

function FeedbackComponent() {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { score, setScore, feedback, setFeedback, sendFeedback, show, setShow } = useFeedbackStore();


    const onButtomSubmit = async (onClose) => {
        await sendFeedback();
        onClose();
    }

    useEffect(() => {
        let timerId;

        const handleMouseLeave = (event) => {
            if (
                show &&
                (event.clientY <= 0 ||
                    event.clientX <= 0 ||
                    event.clientX >= window.innerWidth ||
                    event.clientY >= window.innerHeight)
            ) {
                onOpen(); // Open the modal
                setShow(false); // Ensure it doesn't open again
            }
        };

        const enableMouseLeaveListener = () => {
            document.addEventListener("mouseleave", handleMouseLeave);
        };

        // Start the timer for 1 minute
        timerId = setTimeout(() => {
            enableMouseLeaveListener();
        }, 5000);

        // Cleanup both the event listener and timer
        return () => {
            clearTimeout(timerId);
            document.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [onOpen, show, setShow]);

    return (
        <>
            <div onClick={onOpen} className='absolute bottom-[250px] right-0 bg-primary-300 text-white dark:text-zinc-300 dark:bg-zinc-600 py-1 sm:py-3 px-1 [writing-mode:vertical-lr] rotate-180 z-10 cursor-pointer rounded-r-large'>
                <span className='hidden sm:flex'>Feedback</span>
                <QuestionmarkIconComponent className='size-8 rotate-180 flex sm:hidden' />

            </div>
            <div>
                <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                    <ModalContent className='bg-slate-50 dark:bg-zinc-800'>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col text2xl gap-1 text-center pt-6">We value your feedback</ModalHeader>
                                <ModalBody className='text-center'>
                                    <p>
                                        How likely are&nbsp;you to&nbsp;recommend our&nbsp;service&nbsp;to&nbsp;a&nbsp;friend?
                                    </p>
                                    <div className='flex justify-center gap-2'>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => <div key={i} onClick={() => setScore(i)} className={`p-2 rounded-md w-8 cursor-pointer ${i > 6 ? i > 8 ? 'bg-success-500' : 'bg-warning-300' : 'bg-danger-200'} ${score === i ? ' outline outline-primary dark:outline-white' : ''} ${score < i && score !== null ? 'opacity-40' : ''}`}>
                                            {i}
                                        </div>
                                        )}
                                    </div>
                                    <p className='mt-6'>
                                        What can we improve? Your thoughts matter!
                                    </p>

                                    <TextareaAutosize
                                        autoFocus={false}
                                        maxRows={5}
                                        minRows={3}
                                        className="p-2 rounded-md bg-gray-200 dark:bg-zinc-700 flex max-w-full w-full py-2  outline-none placeholder:text-black dark:placeholder:text-white placeholder:opacity-40 resize-none  
                                        [&::-webkit-scrollbar]:w-3
                                        [&::-webkit-scrollbar-track]:bg-gray-100
                                        [&::-webkit-scrollbar-thumb]:bg-gray-300
                                        dark:[&::-webkit-scrollbar-track]:bg-neutral-700
                                        dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 cursor-auto"
                                        cols="30"
                                        rows="1"
                                        onChange={(e) => setFeedback(e.target.value)}
                                        value={feedback}
                                        placeholder="Write your message"
                                        aria-label="Write your message here" />
                                </ModalBody>
                                <ModalFooter className='flex justify-center'>
                                    <Button color="primary" className='mt-2 bg-primary dark:bg-primary-300' isDisabled={score === null} onPress={() => onButtomSubmit(onClose)}>
                                        Submit feedback
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default FeedbackComponent