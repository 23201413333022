import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { pb } from "../services/pb";
import toast from "react-hot-toast";

const useFeedbackStore = create(
    persist(
        (set, get) => ({
            score: null,
            setScore: (score) => set({ score }),
            feedback: "",
            show: true,
            setShow: (show) => set({ show }),
            setFeedback: (feedback) => set({ feedback }),
            sendFeedback: async () => {
                const { feedback, score } = get();
                if (score) {
                    const data = {
                        "user": pb.authStore.model.id,
                        "score": score,
                        "text": feedback
                    };

                    try {
                        const record = await pb.collection('feedbacks').create(data)
                        console.log(record);
                        toast.success("Feedback sent successfully!");
                    } catch (error) {
                        console.error("Error sending feedback", error);
                    } finally {
                        set({ score: null, feedback: "" });
                    }
                }
            }
        }),
        {
            name: 'feedback-storage',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useFeedbackStore;
