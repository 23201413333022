import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuToggle } from '@nextui-org/react';
import React, { useState } from 'react'
import LogoComponent from '../../assets/LogoIconComponent';
import { Link } from 'react-router-dom';
import ThemeSwitchComponent from '../buttons/ThemeSwitchComponent';
import { useAuth } from '../../store/AuthStore';
import H1 from '../text/H1';
import ExitComponent from '../../assets/ExitIconComponent';
import { ROUTES } from '../../constants/routes';
import B3 from '../text/B3';
import AvatarComponent from '../parts/AvatarComponent';
import NewChatButtonComponent from '../buttons/NewChatButtonComponent';
import SidebarSwitchComponent from '../buttons/SidebarSwitchComponent';
import useSettingsStore from '../../store/SettingsStore';
import ChatHistoryComponent from '../chat/ChatHistoryComponent';

function NavBarNavigationComponent() {

    const { user, logout } = useAuth();
    const { hideLeftMenu } = useSettingsStore()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const menuItemsLeft = [
    //     // { title: "Menu Item", to: ROUTES.TEST_ROUTE },
    // ];

    const logOutHandler = () => {
        logout();
    }

    // on choose menu item close menu
    const closeMenu = () => {
        setIsMenuOpen(false);
    }
    // maxWidth={width.letters}
    return (
        <Navbar onMenuOpenChange={setIsMenuOpen} isMenuOpen={isMenuOpen} maxWidth={'full'} className='bg-main' height="4rem">
            <NavbarContent>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="md:hidden"
                />
                <SidebarSwitchComponent className={`hidden ${hideLeftMenu ? "md:flex" : "hidden"}`} />
                <NavbarBrand>
                    <Link to={ROUTES.DASHBOARD} onClick={closeMenu} className='flex items-center gap-2'><LogoComponent className="block mr-2 hidden sm:flex " /><H1>Med<span className='text-[#0865aa] dark:text-[#79b2dd]'>Up</span></H1></Link>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden md:flex gap-1" justify="center">
                <NavbarItem>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NewChatButtonComponent />
                <ThemeSwitchComponent />
                <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                        <AvatarComponent />
                    </DropdownTrigger>
                    <DropdownMenu classNames={{ base: "bg-zinc-950 dark:bg-zinc-950 " }} className='text-foreground rounded-large bg-white dark:bg-zinc-950' aria-label="Profile Actions" variant="flat">
                        <DropdownItem key="profile" className="h-14 gap-2 " textValue="Profile">
                            <p className="font-semibold">{user?.name && user?.name !== '' ? user?.name : null}</p>
                            <B3 className="text-secondary">{user?.email && user?.email !== '' ? user?.email : null}</B3>
                        </DropdownItem>
                        <DropdownItem key="logout" color="danger" textValue="Logout" onClick={logOutHandler} className=''>
                            <div className='flex items-center justify-between'>Logout <ExitComponent /></div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavbarContent>
            <NavbarMenu className="scrollable [&::-webkit-scrollbar]:w-3 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                {/* {
                    menuItemsLeft.map(
                        (item, index) => (
                            <NavbarMenuItem key={`${item.title}-${index}`} className="w-full" size="lg">
                                <Link to={item.to} onClick={closeMenu}>
                                    {item.title}
                                </Link>
                            </NavbarMenuItem>
                        )
                    )
                } */}
                <span className="font-semibold text-lg px-2 mb-2">Dialogs</span>
                <ChatHistoryComponent onClick={closeMenu} />
            </NavbarMenu>
        </Navbar>
    )
}

export default NavBarNavigationComponent